/* eslint-disable react/jsx-pascal-case */
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { graphql } from 'gatsby';

import './legal-notices.styl';

import Container from '../views/components/Container';
import SEO from '../views/components/SEO';
import MainLayout from '../views/layouts/MainLayout';
// import Hero from '../views/layouts/Hero';

const MLPage = ({ location, pageContext: { locale, translations } }) => {
    const site = (
        <a
            href='https://desastre.com'
            target='_blank'
            rel='noreferrer noopener'
        >
            www.desastre.com
        </a>
    );
    const cnilLink = (
        <a
            href='http://www.cnil.fr/vos-droits/vos-traces/les-cookies/'
            target='_blank'
            rel='noreferrer noopener'
        >
            http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
        </a>
    );
    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title='pages.ml.headerTitle'
                description='pages.ml.metaDescription'
                location={location}
                translationsPaths={translations}
            />
            <div className='header-spacer' />
            <main id='content' className='gap'>
                <Container className='page-ml'>
                    <section>
                        <FormattedMessage
                            tagName='h1'
                            id='pages.ml.headerTitle'
                            values={{ site }}
                        />
                        <div>
                            <FormattedMessage
                                tagName='p'
                                id='pages.ml.section.intro'
                                values={{ site }}
                            />
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.editor.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.editor.p'
                                    values={{ br: <br /> }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.owner.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.owner.p'
                                    values={{ br: <br /> }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.hosting.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.hosting.p'
                                    values={{ br: <br /> }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.cgu.title'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.cgu.p'
                                    values={{ site }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.access.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.access.p'
                                    values={{ site }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.copyright.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.copyright.p1'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.copyright.p2'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.copyright.p3'
                                    values={{ site, supp: <sup>er</sup> }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.copyright.p4'
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.links.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.links.p1'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.links.p2'
                                    values={{ site }}
                                />
                            </div>
                            <div>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.photo.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.photo.p'
                                />
                            </div>
                            <div id='user-data'>
                                <FormattedMessage
                                    tagName='h2'
                                    id='pages.ml.section.userData.title'
                                />
                                <FormattedMessage
                                    tagName='h3'
                                    id='pages.ml.section.userData.collected.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.collected.p'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='h3'
                                    id='pages.ml.section.userData.access.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.access.p1'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.access.p2'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.access.p3'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='h3'
                                    id='pages.ml.section.userData.cookie.title'
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.cookie.p1'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.cookie.p2'
                                    values={{ site }}
                                />
                                <FormattedMessage
                                    tagName='h4'
                                    id='pages.ml.section.userData.cookie.moz.title'
                                />
                                <ol>
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.moz.l1'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.moz.l2'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.moz.l3'
                                    />
                                </ol>
                                <FormattedMessage
                                    tagName='h4'
                                    id='pages.ml.section.userData.cookie.ie.title'
                                />
                                <ol>
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.ie.l1'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.ie.l2'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.ie.l3'
                                    />
                                </ol>
                                <FormattedMessage
                                    tagName='h4'
                                    id='pages.ml.section.userData.cookie.opera.title'
                                />
                                <ol>
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.opera.l1'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.opera.l2'
                                    />
                                </ol>
                                <FormattedMessage
                                    tagName='h4'
                                    id='pages.ml.section.userData.cookie.chrome.title'
                                />
                                <ol>
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.chrome.l1'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.chrome.l2'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.chrome.l3'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.chrome.l4'
                                    />
                                    <FormattedMessage
                                        tagName='li'
                                        id='pages.ml.section.userData.cookie.chrome.l5'
                                    />
                                </ol>
                                <FormattedMessage
                                    tagName='p'
                                    id='pages.ml.section.userData.cookie.p3'
                                    values={{ site: cnilLink }}
                                />
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.userData.socials.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.socials.p'
                                        values={{ site }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h3'
                                        id='pages.ml.section.userData.personnal.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.personnal.p1'
                                        values={{ site }}
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.personnal.p2'
                                        values={{ site }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        tagName='h2'
                                        id='pages.ml.section.userData.right.title'
                                    />
                                    <FormattedMessage
                                        tagName='p'
                                        id='pages.ml.section.userData.right.p'
                                        values={{ site }}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Container>
            </main>
        </MainLayout>
    );
};

MLPage.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        translations: PropTypes.array.isRequired,
    }).isRequired,
    // data: PropTypes.shape({
    //     hero: PropTypes.object.isRequired,
    // }),
};

export default MLPage;

// export const pageQuery = graphql`
//     query {
//         hero: file(
//             sourceInstanceName: { eq: "hero" }
//             relativePath: { eq: "group.jpg" }
//         ) {
//             ...BgImages
//         }
//     }
// `;
